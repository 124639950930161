@import url("//fonts.googleapis.com/css?family=Source+Sans+Pro:400,700|Source+Serif+Pro:400");

:root {
  --theme-primary: hsl(351, 73%, 37%);
  --theme-secondary: hsl(208, 100%, 15%);
  --theme-shadow: hsl(230, 15%, 16%);
  --theme-black: hsl(180, 100%, 0%);
  --theme-white: hsl(192, 100%, 99%);
  --theme-grey: hsl(0, 0%, 95%);

  --font-family-text: "Source Serif Pro", Georgia, serif;
  --font-family-numeric: sans-serif;
  --font-family-code: "Fira code", "Fira Mono", Inconsolata, Menlo, monospace;
  --font-family-display: "Source Sans Pro", sans-serif;
  --font-weight-display: 700;

  --font-size-a: 1.5em;
  --font-size-b: 1.5em;
  --font-size-c: 1.25em;
  --font-size-d: 0.8em;
  --font-size-e: var(--font-size-d);
  --font-size-f: var(--font-size-d);
}

@media (min-width: 412px) {
  :root {
    --font-size-a: 2em;
    --font-size-b: 1.5em;
    --font-size-c: 1.25em;
    --font-size-d: 0.8em;
    --font-size-e: var(--font-size-d);
    --font-size-f: var(--font-size-d);
  }
}

@media (min-width: 545px) {
  :root {
    --font-size-a: 3em;
    --font-size-b: 2.5em;
    --font-size-c: 2em;
    --font-size-d: 1.5em;
    --font-size-e: 1.25em;
    --font-size-f: 0.8em;
  }
}

html,
body {
  background-color: var(--theme-white);
  color: var(--theme-black);
  font-weight: 400;
  line-height: 1.45;
  font-size: 16px;
}

header,
main,
footer {
  padding: 1em;
}

@media (min-width: 570px) {
  header,
  main,
  footer {
    /* 570px == width of 3 Select inputs */
    padding: 1em calc((100vw - 570px) / 2);
  }
}

header {
  background: var(--theme-shadow);
}

header h1 {
  font-family: var(--font-family-code);
  color: var(--theme-white);
  margin: 0;
  display: inline-block;
  padding-right: 0.25em;
  border-right: 5px solid var(--theme-white);
  animation: cursor-blink 1s steps(5, start) infinite;
}
@keyframes cursor-blink {
  to {
    border-color: transparent;
  }
}
header h1 + small {
  color: var(--theme-white);
  display: block;
  font-size: 1em;
  font-weight: var(--font-weight-display);
  padding-left: 2px;
}

p,
ul,
ol {
  font-family: var(--font-family-text);
  margin-bottom: 1.25em;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--theme-primary);
  font-family: var(--font-family-display);
  font-weight: var(--font-weight-display);
  line-height: 1.15;
  margin: 2.75rem 0 1rem;
}

h1 {
  font-size: var(--font-size-a);
}

h2 {
  margin-top: 0;
  font-size: var(--font-size-b);
}

h3 {
  font-size: var(--font-size-c);
}

h4,
h5,
h6,
small {
  font-size: var(--font-size-d);
}

/* - - - - - - - */

.Select {
  display: inline-block;
  width: calc(100% - 1em);
  margin: 0.5em 1em 0.5em 0;
  padding: 0.25em;
  border-radius: 0.25em;
  font-weight: bold;
  color: var(--theme-shadow);
  background: var(--theme-grey);
  border: 3px solid transparent;
}
@media (min-width: 412px) {
  .Select {
    width: 10em;
  }
}

.Select:hover,
.Select:active {
  border-color: var(--theme-secondary);
}

.Select--selected {
  color: var(--theme-white);
  background: var(--theme-secondary);
}
.Select input {
  margin: 1em;
}

.Select--ranking-system {
}

/* - - - - - - - */

.ListHeader {
  color: var(--theme-secondary);
  border-bottom: 5px solid var(--theme-primary);
  vertical-align: bottom;
  margin: 0 !important;
  padding: 0.75em 0 0.25em 0.5em;
  position: relative;
}

.ListHeader--disclosure-triangle {
  font-weight: 500;
  font-size: var(--font-size-d);
  position: absolute;
  right: 0;
}

.Factor {
}

.Factor--name {
  font-family: var(--font-family-display);
  font-weight: 400;
}

span.Factor--order-indicator {
  font-size: 0.5rem;
}
button.Factor--order-indicator {
  transform: scale(0.6) translate(-15px);
  position: absolute;
}

.Factor--children {
  padding-left: 2.4em;
}

/* - - - - - - - */

.SelectedFactors {
  margin: 1em -1em;
  padding: 1em 1em 2em 1em;
  background: var(--theme-grey);
}

@media (min-width: 570px) {
  .SelectedFactors {
    box-sizing: border-box;
    width: 100vw;
    padding: 1em calc((100vw - 545px + 2em) / 2);
    margin: 1em 0;
    margin-left: calc((-100vw + 545px + 1.5em) / 2);
  }
}

.SelectedFactors h3 {
  margin-top: 0;
  color: var(--theme-shadow);
}

.SelectedFactors ol {
  margin: 0;
  padding: 0;
}
.SelectedFactors ol > li {
  list-style-type: none;
}
.SelectedFactors ol > li:nth-child(even) {
  background-color: var(--theme-white);
}

/* - - - - - - - */

.AvailableDataSources {
  margin: 1em -1em;
  padding: 1em 1em 2em 1em;
  background: var(--theme-shadow);
  color: var(--theme-white);
}
@media (min-width: 570px) {
  .AvailableDataSources {
    box-sizing: border-box;
    width: 100vw;
    padding: 1em calc((100vw - 545px + 2em) / 2);
    margin: 1em 0;
    margin-left: calc((-100vw + 545px + 1.5em) / 2);
  }
}

.AvailableDataSources h3 {
  margin-top: 0;
  color: var(--theme-grey);
}

.AvailableDataSources ul {
  margin: 0;
  padding: 0;
}
.AvailableDataSources ul > li {
  list-style-type: none;
}
.AvailableDataSources ul ul li:nth-child(even) {
  background-color: var(--theme-black);
}

/* - - - - - - - */

.Button {
  border: 0;
  background: transparent;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  text-align: inherit;
  cursor: pointer;
}

.Button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.Button-heavy {
  font-weight: 900;
}

/* - - - - - - - */

.Typeahead {
  -webkit-appearance: none;
  height: 3em;
  border: 0;
  margin: 0 1em 0 0;
  padding: 0.5em 1em;
}

/* - - - - - - - */

.Ranks {
  max-width: 100vw;
  overflow: scroll;
}

@media (min-width: 545px) {
  .Ranks {
    padding: 1em;
  }
}
@media (min-width: 570px) {
  .Ranks.Ranks--full-width {
    width: calc(100vw - 2em);
    margin-left: calc((-100vw + 570px - 0em) / 2);
  }
}

.Ranks--table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}

.Ranks--table tr {
  background-color: var(--theme-white);
}

.Ranks--table thead tr > * {
  color: var(--theme-secondary);
  border-bottom: 5px solid var(--theme-primary);
  vertical-align: bottom;
}
.Ranks--table tr > * {
  margin: 0;
}
/* Rank column */
.Ranks--table th:first-child {
  width: 40px;
}

.Ranks--table tbody tr > * {
  vertical-align: baseline;
}
.Ranks--table tbody tr img {
  vertical-align: bottom;
  width: 32px;
  padding: 0 10px 0 5px;
  position: relative;
  bottom: -3px;
}
.Ranks--table tr:nth-child(even) {
  background-color: var(--theme-grey);
}
.Ranks--table th {
  margin: 0;
  padding: 0.75em 0 0.25em 0.5em;
}
.Ranks--table td {
  margin: 0;
  padding: 0.25em 0.5em 0.5em 0.5em;
}
.Ranks--table th {
  text-align: right;
}
.Ranks--table th:nth-child(1),
.Ranks--table th:nth-child(2) {
  text-align: left;
}
.Ranks--table td {
  text-align: right;
  font-family: var(--font-family-numeric);
}
.Ranks--table td:nth-child(1) {
  font-weight: bold;
  font-family: var(--font-family-display);
}
.Ranks--table td:nth-child(2) {
  text-align: left;
  font-family: var(--font-family-text);
}

.Rank-mascot-name {
  font-style: italic;
}

.Stat--most-significant {
  font-weight: bold;
}
.Stat--insignificant {
  color: var(--theme-shadow);
}

/* - - - - - - - */

/* Syntax highlighting */
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #90a4ae;
}
.token.punctuation {
  color: #9e9e9e;
}
.namespace {
  opacity: 0.7;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #e91e63;
}
.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #4caf50;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #795548;
}
.token.atrule,
.token.attr-value,
.token.keyword {
  color: #3f51b5;
}
.token.function {
  color: #f44336;
}
.token.regex,
.token.important,
.token.variable {
  color: #ff9800;
}
.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}
